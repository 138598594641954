import { Moon, SunDim } from "phosphor-react";
import React, { useRef } from "react";
import "./Light.css";

const Light = ({ lighthandler, isDark }) => {
  const hoverText = useRef();
  return (
    <>
      <div id="light">
        <span className="icon">
          <i className="fas fa-sun"></i>
        </span>
        <button onClick={lighthandler} className="light-btn">
          {(isDark && <SunDim size={36} color="#fafafa" weight="fill" />) || (
            <Moon size={36} color="#000" weight="fill" />
          )}
          <div ref={hoverText} className="light-hover">
            <span>{(isDark && "Switch Light mode") || "Switch dark mode"}</span>
          </div>
        </button>
      </div>
    </>
  );
};

export default Light;
