import React, { useState } from "react";
import { FaHome } from "react-icons/fa";
import {
  FiBriefcase,
  FiFileText,
  FiPhoneOutgoing,
  FiUser,
} from "react-icons/fi";
import { VscServerProcess } from "react-icons/vsc";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import Typewriter from "typewriter-effect";

const HeaderTwo = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  return (
    <>
      {/* Header */}
      <div className="mob-header">
        <button className="toggler-menu" onClick={handleClick}>
          <div className={click ? "active" : ""}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      {/* End Header */}

      {/* nav bar */}
      <header
        className={
          click
            ? "header-left header-menu-style-two menu-open"
            : "header-left header-menu-style-two"
        }
      >
        <div className="scroll-bar">
          <div className="hl-top">
            <div className="hl-logo">
              <Link to="/">
                <Typewriter
                  options={{
                    strings: ["C", "O", "D", "A", "V", "J", "A", "S"],
                    autoStart: true,
                    loop: true,
                    delay: 75,
                  }}
                />
              </Link>
            </div>
          </div>
          {/* End htl-top */}

          <Scrollspy
            className="nav nav-menu"
            items={[
              "hem",
              "om-oss",
              "tjänster",
              "portfolio",
              "process",
              "kontakt",
            ]}
            currentClassName="active"
            offset={-30}
          >
            <li>
              <a className="nav-link " href="#hem" onClick={handleClick}>
                <FaHome />
                <span className="item">Hem</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#om-oss" onClick={handleClick}>
                <FiUser />
                <span className="item">Om oss</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#tjänster" onClick={handleClick}>
                <FiFileText />
                <span className="item">Tjänster</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#portfolio" onClick={handleClick}>
                <FiBriefcase />
                <span className="item">Portfolio</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#process" onClick={handleClick}>
                <VscServerProcess />
                <span className="item">Process</span>
              </a>
            </li>
            <li>
              <a className="nav-link" href="#kontakt" onClick={handleClick}>
                <FiPhoneOutgoing />
                <span className="item"> Kontakt</span>
              </a>
            </li>
          </Scrollspy>
        </div>
      </header>
      {/* End Header */}
    </>
  );
};

export default HeaderTwo;
