import React from "react";
import TextLoop from "react-text-loop";

const sliderContent = {
  name: "VI SÄTTER DIGITALISERING I RULLNING...",
  btnText: "KONTAKTA OSS",
};

const Slider = ({ img, img1 }) => {
  const hoverHandler = (e) => {
    e.target.src = img1;
  };
  const outHandler = (e) => {
    e.target.src = img;
  };

  return (
    <>
      {/*  Home Banner */}
      <section id="hem" className="home-banner">
        <div className="hb-top-fixed d-flex">
          <div className="brand-img">
            <img
              onMouseOver={(e) => hoverHandler(e)}
              onMouseOut={(e) => outHandler(e)}
              src={img}
              alt="codavjas"
            />
          </div>
        </div>
        {/* End hp-top-fixed */}

        <div className="container">
          <div className="row full-screen align-items-center">
            <div className="col-lg-7">
              <div className="type-box">
                <h6 data-aos="fade-up" data-aos-duration="1200">
                  HEJ! 👋 VÄLKOMMEN TILL CODAVJAS
                </h6>
                <h1
                  className="font-alt"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="100"
                >
                  {sliderContent.name}
                </h1>
                <div
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
                >
                  <TextLoop>
                    <p className="loop-text lead">APPUTVECKLARE</p>
                    <p className="loop-text lead">WEBBUTVECKLARE</p>
                    <p className="loop-text lead">BACKENDUTVECKLARE</p>
                    <p className="loop-text lead">FRONTEND</p>
                    <p className="loop-text lead">GRÄNSSNITTSUTVECKLING</p>
                    <p className="loop-text lead">SYSTEMUTVECKLING</p>
                    <p className="loop-text lead">INTEGRATIONER</p>
                    <p className="loop-text lead">WEBBAPPLIKATIONER</p>
                    <p className="loop-text lead">DRIFT OCH FÖRVALTNING</p>
                    <p className="loop-text lead">UX/UI DESIGN</p>
                    <p className="loop-text lead">GROWTH HACKING</p>
                    <p className="loop-text lead">KONSULTING</p>
                    <p className="loop-text lead">
                      IMPLEMENTERING OCH OPTIMERING
                    </p>
                    <p className="loop-text lead">CHATT & RÖSTAPPAR</p>
                    <p className="loop-text lead">APP ANALYS</p>
                  </TextLoop>
                </div>

                <p
                  className="desc"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                ></p>
                <div
                  className="mt-4"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="400"
                >
                  <a className="px-btn px-btn-white" href="#kontakt">
                    {sliderContent.btnText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Container*/}

        <div className="go-to go-to-next">
          <a href="#about">
            <span></span>
          </a>
        </div>
      </section>

      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
