import React from "react";

const ServiceContent = [
  {
    icon: "icon-mobile",
    title: "APPUTVECKLING",
    descriptions: `I dagens samhälle finns möjligheten att göra det mesta från sin mobiltelefon: beställa transport och mat, handla varor, socialisera, omvärldövervakning samtidigt som en lyssnar på musik eller tittar på en video och mycket därtill. Företag hänger med i utvecklingen och erbjuder sina varor och tjänster till kunder via appar. Rimligt, eftersom appar är en smidig lösning då innehåll, funktioner och gränsssnitt anpassas på effektivt sätt vid nyttjandes av en mobil enhet. Utan ett backendsystem, API:er, administration och serverlösningar som effektivt lagrar och hanterar datamängder som skickas från och till applikationer, är en app bara en död produkt.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-desktop",
    title: "WEBBUTVECKLING",
    descriptions: `Idag finns det mesta webbaserat. Det är en självklarhet att nå tjänster vare sig det gäller via dator eller mobil. Dessutom förändras spelreglerna snabbt! De företagen som inte anpassar sina processer till den föränderliga vardagen kommer stå som förlorare mot sina konkurrenter. Många gånger är det över tid billigare att utveckla ett skräddarsytt webbsystem som förbättrar ett företags behov, rutiner och processer än att köpa in ett dyrt system, befintligt på marknaden.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-target",
    title: "DIGITAL DESIGN",
    descriptions: `Det företag som idag har en snygg och modern webbplats är den som får kunderna i jämförelse med ett företag som säljer samma vara/tjänst men vars hemsida inte är en fröjd för kundens öga. Det är inte bara kundens glädje och köpupplevelse som styr utan designen säger mycket om själva företaget.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-laptop",
    title: "DATA ANALYTICS",
    descriptions: `En strategisk tillgång idag är data som du redan äger. Med denna tillgång kan du identifiera förbättringar intern och mot dina kunder, förutspå framtiden och fatta beslut på fakta i stället för på känsla.`,
    delayAnimation: "200",
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-4 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
