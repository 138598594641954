import { Accordion } from "react-bootstrap";
import "./Collaps.css";

export default function Collaps() {
  return (
    <div className="custom-collaps">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>TRANSPARENS </Accordion.Header>
          <Accordion.Body>
            Vi håller en ärlig och öppen kommunikation med er som kund där ni
            involveras genom projektets samtliga faser. Vi tror detta är en är
            en nyckelfaktor för en god och produktiv relation och ett lyckat
            resultat.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>KUNDORIENTERAT</Accordion.Header>
          <Accordion.Body>
            Varje projekt arbetar vi på som om det vore vårt egna. Men vi vill
            att kunden alltid ska vara nöjd och låter därför kunder enkelt och
            löpande kunna återkoppla på arbete och involveras i beslut.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>ENGAGEMANG</Accordion.Header>
          <Accordion.Body>
            Vårt team lyfter förslag på förbättringar, tar eget initiativ för
            lärande, delar kunskap och kompetens, förutser hinder och snabb
            återkoppling med detaljer för nya uppgifter. Såklart gäller detta
            internt såväl som mot våra kunder.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
