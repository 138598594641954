import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Light from "../components/Light-btn/Light";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import HomeDarkAnimation from "../views/all-home-version/HomeDarkAnimation";
import HomeLightRtlAnimation from "../views/all-home-version/HomeLightAnimation";
import NotFound from "../views/NotFound";

const Routes = () => {
  const [isdark, setIsDark] = useState(true);

  const lighthandler = () => {
    if (isdark) {
      setIsDark(false);
      document.querySelector("#root").classList.add("light");
    } else {
      setIsDark(true);
      document.querySelector("#root").classList.remove("light");
    }
  };

  return (
    <>
      <Light isDark={isdark} lighthandler={lighthandler} />
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route
            exact
            path="/"
            component={(isdark && HomeDarkAnimation) || HomeLightRtlAnimation}
          />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
