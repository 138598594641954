import React from "react";
import Masonry from "react-masonry-css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import img1 from "./portfolio/1.jpg";
import img11 from "./portfolio/11.jpg";
import img12 from "./portfolio/12.jpg";
import img13 from "./portfolio/13.jpg";
import img14 from "./portfolio/14.jpg";
import img15 from "./portfolio/15.jpg";
import img16 from "./portfolio/16.jpg";
import img17 from "./portfolio/17.jpg";
import img18 from "./portfolio/18.jpg";
import img19 from "./portfolio/19.jpg";
import img2 from "./portfolio/2.jpg";
import img20 from "./portfolio/20.jpg";
import img21 from "./portfolio/21.jpg";
import img22 from "./portfolio/22.jpg";
import img3 from "./portfolio/3.jpg";
import img4 from "./portfolio/4.jpg";
import img5 from "./portfolio/5.jpg";
import img6 from "./portfolio/6.jpg";
import img7 from "./portfolio/7.jpg";
import img8 from "./portfolio/8.jpg";
import img9 from "./portfolio/9.jpg";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const tabList = [
  "ALLA",
  "WEBBSIDOR",
  "APPLIKATIONER ",
  "DIGITAL DESIGN",
  "ANIMERAD FILM",
];
const tabListContent = [
  {
    porftoliItems: [
      {
        img: img1,
        title: "HUMANASECONDHAND AB",
        subTitle: "Framtagen logotyp",
        alterText: "HUMANASECONDHAND AB",
        delayAnimation: "",
      },

      {
        img: img2,
        title: "BEAUTY BOULEVARD",
        subTitle:
          "Design och layout  samt utveckling av ny webbplats. Framtagna specialfunktioner, Klarna integration, woocommerce integration.",
        alterText: "BEAUTY BOULEVARD",
        delayAnimation: "100",
      },

      {
        img: img3,
        title: "BRF MELODIN",
        subTitle:
          "Design och layout  samt utveckling av ny webbplats. Framtagna specialfunktioner samt molnlagringsintegration",
        alterText: "MELODIN",
        delayAnimation: "200",
      },
      {
        img: img4,
        title: "DADOSKÖK AB",
        subTitle: "Design och utveckling av webbplats med specialfunktioner.",
        alterText: "E-Cosmetics",
        delayAnimation: "0",
      },
      {
        img: img5,
        title: "XPRHEALTH AB",
        subTitle: "Mockup framtagning",
        alterText: "Bottle Illustration",
        delayAnimation: "100",
      },
      {
        img: img6,
        title: "AIRPODSFORDAL",
        subTitle:
          "Design och layout . Framtagen webbplats med e-butik och flertal integrationer",
        alterText: "Web Application",
        delayAnimation: "200",
      },
      {
        img: img7,
        title: "LODRENTAL AB",
        subTitle: "Animerad film framtagning",
        alterText: "Business Card",
        delayAnimation: "0",
      },
      {
        img: img8,
        title: "STOCKHOLMSELTECH AB",
        subTitle:
          "Design och layout av ny webbplats med flertal specialfunktioner inom byggbranschen",
        alterText: "Chatting App Design",
        delayAnimation: "100",
      },
      {
        img: img9,
        title: "HUMANASECONDHAND",
        subTitle: "Tradera API integration och utveckling av webbplats",
        alterText: "Web Motion",
        delayAnimation: "200",
      },
      {
        img: img11,
        title: "LODRENTAL AB ",
        subTitle: "Framtagen logotyp",
        alterText: "Business Mockup",
        delayAnimation: "100",
      },
      {
        img: img12,
        title: "BRF MELODIN",
        subTitle: "Framtagen logotyp",
        alterText: "Chatting App Design",
        delayAnimation: "200",
      },
      {
        img: img13,
        title: "DADOSKÖK AB",
        subTitle: "Framtagen logotyp",
        alterText: "E-Cosmetics",
        delayAnimation: "300",
      },
      {
        img: img14,
        title: "ABRAA AB",
        subTitle: "Framtagen logotyp",
        alterText: "Illustration",
        delayAnimation: "",
      },

      {
        img: img15,
        title: "DADOSKÖK AB",
        subTitle: "Framtagen logotyp",
        alterText: "Bottle Illustration",
        delayAnimation: "100",
      },
      {
        img: img16,
        title: "NEXTLEVEL AB",
        subTitle: "Framtagen logotyp",
        alterText: "Web Application",
        delayAnimation: "200",
      },
      {
        img: img17,
        title: "NEXTLEVEL AB",
        subTitle:
          "Design och layout av ny webbplats med flertal specialfunktioner inom byggbranschen",
        alterText: "Chatting App Design",
        delayAnimation: "200",
      },
      {
        img: img18,
        title: "ABRAA AB",
        subTitle:
          "OnlyFrieends Design och utveckling av mobilapplikationpputveckling med specialfunktioner. För både Android, IOS",
        alterText: "E-Cosmetics",
        delayAnimation: "300",
      },
      {
        img: img19,
        title: "SOUKRA AB",
        subTitle:
          "Design och utveckling av mobilapplikationpputveckling med specialfunktioner. För både Android, iOS ",
        alterText: "Illustration",
        delayAnimation: "",
      },

      {
        img: img20,
        title: "DADOSKÖK ",
        subTitle:
          "Design och utveckling av webbapplikation inom restaurang med tillhörande adminpanel",
        alterText: "Bottle Illustration",
        delayAnimation: "100",
      },
      {
        img: img21,
        title: "T.KH Entreprenad AB",
        subTitle:
          "Design och layout samt  utveckling av  ny webbplats med flertal specialfunktioner inom byggbranschen",
        alterText: "Web Application",
        delayAnimation: "200",
      },
      {
        img: img22,
        title: "SOUKRA AB",
        subTitle:
          "Design och layout av ny webbplats med flertal specialfunktioner inom byggbranschen",
        alterText: "Web Application",
        delayAnimation: "200",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: img3,
        title: "BRF MELODIN",
        subTitle:
          "Design och layout  samt utveckling av ny webbplats. Framtagna specialfunktioner samt molnlagringsintegration",
        alterText: "MELODIN",
        delayAnimation: "200",
      },
      {
        img: img9,
        title: "HUMANASECONDHAND",
        subTitle: "Tradera API integration och utveckling av webbplats",
        alterText: "Web Motion",
        delayAnimation: "200",
      },
      {
        img: img4,
        title: "DADOSKÖK AB",
        subTitle: "Design och utveckling av webbplats med specialfunktioner.",
        alterText: "E-Cosmetics",
        delayAnimation: "0",
      },
      {
        img: img8,
        title: "STOCKHOLMSELTECH AB",
        subTitle:
          "Design och layout av ny webbplats med flertal specialfunktioner inom byggbranschen",
        alterText: "Chatting App Design",
        delayAnimation: "100",
      },
      {
        img: img2,
        title: "BEAUTY BOULEVARD",
        subTitle:
          "Design och layout  samt utveckling av ny webbplats. Framtagna specialfunktioner, Klarna integration, woocommerce integration.",
        alterText: "BEAUTY BOULEVARD",
        delayAnimation: "100",
      },
      {
        img: img6,
        title: "AIRPODSFORDAL",
        subTitle:
          "Design och layout . Framtagen webbplats med e-butik och flertal integrationer",
        alterText: "Web Application",
        delayAnimation: "200",
      },
      {
        img: img20,
        title: "DADOSKÖK ",
        subTitle:
          "Design och utveckling av webbapplikation inom restaurang med tillhörande adminpanel",
        alterText: "Bottle Illustration",
        delayAnimation: "100",
      },
      {
        img: img21,
        title: "T.KH Entreprenad AB",
        subTitle:
          "Design och layout samt  utveckling av  ny webbplats med flertal specialfunktioner inom byggbranschen",
        alterText: "Web Application",
        delayAnimation: "200",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: img19,
        title: "SOUKRA AB",
        subTitle:
          "Design och utveckling av mobilapplikationpputveckling med specialfunktioner. För både Android, iOS ",
        alterText: "Illustration",
        delayAnimation: "",
      },

      {
        img: img18,
        title: "ABRAA AB",
        subTitle:
          "OnlyFrieends Design och utveckling av mobilapplikationpputveckling med specialfunktioner. För både Android, IOS",
        alterText: "E-Cosmetics",
        delayAnimation: "300",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: img1,
        title: "HUMANASECONDHAND AB",
        subTitle: "Framtagen logotyp",
        alterText: "HUMANASECONDHAND AB",
        delayAnimation: "",
      },
      {
        img: img5,
        title: "XPRHEALTH AB",
        subTitle: "Mockup framtagning",
        alterText: "Bottle Illustration",
        delayAnimation: "100",
      },
      {
        img: img16,
        title: "NEXTLEVEL AB",
        subTitle: "Framtagen logotyp",
        alterText: "Web Application",
        delayAnimation: "200",
      },
      {
        img: img15,
        title: "DADOSKÖK AB",
        subTitle: "Framtagen logotyp",
        alterText: "Bottle Illustration",
        delayAnimation: "100",
      },
      {
        img: img11,
        title: "LODRENTAL AB ",
        subTitle: "Framtagen logotyp",
        alterText: "Business Mockup",
        delayAnimation: "100",
      },
      {
        img: img13,
        title: "DADOSKÖK AB",
        subTitle: "Framtagen logotyp",
        alterText: "E-Cosmetics",
        delayAnimation: "300",
      },
      {
        img: img14,
        title: "ABRAA AB",
        subTitle: "Framtagen logotyp",
        alterText: "Illustration",
        delayAnimation: "",
      },
      {
        img: img12,
        title: "BRF MELODIN",
        subTitle: "Framtagen logotyp",
        alterText: "Chatting App Design",
        delayAnimation: "200",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: img7,
        title: "LODRENTAL AB",
        subTitle: "Animerad film framtagning",
        alterText: "Business Card",
        delayAnimation: "0",
      },
    ],
  },
];

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            {tabList.map((val, i) => (
              <Tab key={i}>{val}</Tab>
            ))}
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
            {tabListContent.map((tabContent, i) => (
              <TabPanel key={i}>
                <div className="portfolio-content ">
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {tabContent.porftoliItems.map((val, i) => (
                      <div
                        className="portfolio-box-01"
                        key={i}
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay={val.delayAnimation}
                      >
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a
                                href={val.portfolioLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a href={val.img} className="gallery-link">
                            <img src={val.img} alt={val.alterText} />
                          </a>
                          {/* End gallery link */}

                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                {/* End list wrapper */}
              </TabPanel>
            ))}
          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
