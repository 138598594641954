import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>KONTAKTA OSS</h4>
        <p>
          CODAVJAS hjälper sina kunder nå affärsmål och hantera dess utmaningar
          med digitala lösningar inom design och utveckling
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              Västbergavägen 4, 126 30 Hägersten
            </span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-envelope"></i>
            <span className="media-body">
              <a href={`mailto:info@codavjas.se`}> info@codavjas.se</a>
            </span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <span className="media-body">
              <a href={`tel:+46 (0)73-652 49 88`}>+46 (0)73-652 49 88</a>
            </span>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
