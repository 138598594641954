import React, {useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import axios from "axios";

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: false,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    margin: 30,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [testimonialData, setTestimonialData] = useState(null);
  useEffect(() => {
    // GET request using axios inside useEffect React hook
    axios
      .get("./omdomen/get_testimonial.php")
      .then((response) => {
        var testimonailData = response.data;
        setTestimonialData(testimonailData);
      });
  }, []);

  return (
    <div className="testimonial-wrapper">
      <Slider {...settings}>
        {testimonialData && testimonialData.map((data) => (
          <div key={data.id}>
            <div className="testimonial-01 media">
              <div className="media-body">
                <p>{data.text}</p>
                <h6>{data.title}</h6>
                <span>{data.company}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
