import React from "react";

const Map = () => {
  return (
    <>
      <div className="google-map">
        <div className="embed-responsive embed-responsive-21by9">
          <iframe
            className="embed-responsive-item"
            title="location title"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2037.3761597715875!2d18.003519316380665!3d59.293274681646224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f77aeccaa6449%3A0x3a5c8cf5626a9fb9!2zVsOkc3RiZXJnYXbDpGdlbiA0LCAxMjYgMzAgSMOkZ2Vyc3RlbiwgU3dlZGVu!5e0!3m2!1sen!2sbd!4v1643102856324!5m2!1sen!2sbd"
          ></iframe>
        </div>
      </div>
      {/* End google-map */}
    </>
  );
};

export default Map;
