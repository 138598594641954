import React from "react";
import Services from "../service/ServiceAnimation";

const Resume = () => {
  return (
    <>
      <section id="tjänster" className="section">
        <div className="container">
          <div className="title">
            <h3>TJÄNSTER</h3>
          </div>

          <Services />

          {/* End .row */}
        </div>
      </section>
    </>
  );
};

export default Resume;
