import React from "react";
import Slider from "react-slick";
import Awards from "../award/AwardsAnimation";
import Collaps from "../collaps/Collaps";
import Testimonials from "../testimonial/TestimonialAnimation";

const About = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <section id="om-oss" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <Slider {...settings}>
                <div className="about-me">
                  <div className="img">
                    <div className="img-in">
                      <img src="img/about/1.png" alt="about" />
                    </div>

                    {/* End social icon */}
                  </div>
                  {/* End img */}

                  <div className="info">
                    <p>CEO | CTO</p>
                    <h3>Jaswinder Singh</h3>
                  </div>

                  {/* End info */}
                </div>
                <div className="about-me">
                  <div className="img">
                    <div className="img-in">
                      <img src="img/about/2.png" alt="about" />
                    </div>

                    {/* End social icon */}
                  </div>
                  {/* End img */}

                  <div className="info">
                    <p>COO | CTO</p>
                    <h3>David Brevik</h3>
                  </div>

                  {/* End info */}
                </div>
              </Slider>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>VI BRYR OSS OM DIN DIGITALA UTVECKLING</h3>
                </div>
                <div className="about-text">
                  <p>
                    Codavjas AB tror stenhårt på goda relationer – både inom
                    egna bolaget såväl med våra kunder. Vi står för transparens,
                    engagemang och att ständigt sätta kunden först. Är du inte
                    nöjd, är inte vi heller det.
                  </p>
                  <p>
                    Codavjas AB finns för hjälpa sina kunder nå framgång genom
                    olika digitala lösningar. Vi erbjuder olika tjänster inom
                    området design och utveckling. Vårt team är starka inom
                    webbdesign, webbplatsutveckling, grafisk design samt
                    mobilapplikationer.
                  </p>
                </div>
                <div className="info-list">
                  <Collaps />
                </div>
              </div>
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          {/* End separated */}

          <div className="title">
            <h3>TEKNOLOGIER OCH PLATTFORMAR VI ARBETAR MED</h3>
          </div>

          <Awards />
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>KUNDOMDÖMEN</h3>
          </div>

          <Testimonials />
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default About;
