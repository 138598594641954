import React from "react";
import Slider from "react-slick";

const AwardContnet = [
  {
    img: "https://img.icons8.com/color/64/000000/java-coffee-cup-logo--v1.png",
    awardName: "Java",
    awardFor: "",
    delayAnimation: "0",
  },
  {
    img: "https://img.icons8.com/offices/50/000000/php-logo.png",
    awardName: "PHP",
    awardFor: "",
    delayAnimation: "200",
  },
  {
    img: "https://img.icons8.com/color/48/000000/nodejs.png",
    awardName: "NodeJS",
    awardFor: "",
    delayAnimation: "400",
  },

  {
    img: "https://img.icons8.com/color/48/000000/ios-logo.png",
    awardName: "iOS",
    awardFor: "",
    delayAnimation: "400",
  },

  {
    img: "https://img.icons8.com/color/48/000000/android-os.png",
    awardName: "Android",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/javascript--v1.png",
    awardName: "Javascript",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/html-5--v1.png",
    awardName: "HTML & HTML5",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/css3.png",
    awardName: "CSS & CSS3",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/stickers/100/000000/wordpress.png",
    awardName: "WordPress",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/shopify.png",
    awardName: "Shopify",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: `https://img.icons8.com/external-tal-revivo-duo-tal-revivo/25/000000/external-wixcom-ltd-is-an-israeli-cloud-based-web-development-logo-duo-tal-revivo.png`,
    awardName: "WIX",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/fluency/48/000000/python.png",
    awardName: "Python",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/fluency/48/000000/laravel.png",
    awardName: "Laravel ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/dusk/64/000000/ms-share-point.png",
    awardName: "Sharepoint ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/external-tal-revivo-shadow-tal-revivo/24/000000/external-angular-a-typescript-based-open-source-web-application-framework-logo-shadow-tal-revivo.png",
    awardName: `Angular js`,
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/vue-js.png",
    awardName: "Vue JS ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/ultraviolet/40/000000/react--v2.png",
    awardName: "React JS ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: `https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-chatbot-online-shopping-vitaliy-gorbachev-blue-vitaly-gorbachev.png`,
    awardName: "ChatBot ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/flutter.png",
    awardName: "Flutter ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/swift.png",
    awardName: "Swift  ",
    awardFor: "",
    delayAnimation: "400",
  },

  {
    img: "https://img.icons8.com/color/48/000000/visual-studio.png",
    awardName: "Visual studio  ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAADyElEQVRoge2ZXWgcVRiGn292TZrsbGqpv3UziVSxVBELoogIvdAK/pBsaryS4E/xSrG1YitKTZEqMRBr77wQf3Ihdu1u0hQEDYgXXhTBgFLwp5B0stZWW4ntTtLE3fN5kSC6JTuzu7OawrxXy5n3+877sLNz9syBSJEiRbqUJI1sro93rvJmzA4ETay29sv7UxcaNVdDQBSkkHYeEWEA5fql4TzwciLnDgto2HOGDjLbnbpDxRpSuHsZy9dG2NGWdb8Kc97QQGa71rUbie9DeCxAXxX4xCrKrpaxE5NhzF83yK+9V9otpZYXRNkFrKqyfA44MH+h6fW1nx4/V0+OmkF0M/HZy50nVXgNuKqeEMAvovS3Xua+KxlKtTSoCcRLOw8pDAIbfKxzigyhxojITqC1oluZUEt2JrMnvqg2U1Ug57ucjSIMIjzgY73oNzDbm7quVLL2iLINsCpXM64xticPuceCZgsE4vV0XGvQflGeAmI+9opPJW9r++1qZAi4x6fPnyq8R2zhlWTm1G9+GX1BvJ6ObYruR0n4WKdUdLednT7ot04oSKHb6RVhAOj06XsW1WftkemPKpkqf8UAqlsqQgieCnsTcbMxmZ3+OMhiJ6DJEfdgIp68CWU78EcF+1oRSfv19AdZXgZlmJh1QzLr9ksmP1dtA8kcW7BH3LcNpfXAAajtiQW1gigTWGaTPeL22ZmpU+WXZx501nhpZ0/5eKHHGSj0dl5TPt6W+/msnXOfw5hNKBO1RKoNRBi3D+W/LR/WzcS9bufpeBPfK/LMRXUqT1Ayx8/3OP3am2opv2yP5r9DGK8lUj231r9U2Orc661xJlR4h0oLpJIQ5VWvaP1YSDt9GtLfpFBACmnnPgyfA7dUUZYCPvDSqfvDyBAKiIg01V4b81uXAim0W+v/VgSy0hSBrDRFICtNEchKUwSy0uQLosIYMPMfZFlOMwpH/Ey+IHbWHS7FzXpgAFgII1lALe5AS7ENds790M8c6NZancn/bufc3aYUu1UgEyyHNv9zN7j4WZuDlTKOMbfZI26ffXjydJCSeLBQi2o7PPkD8Oi5h9ddEcQuRfOT19P+hhpRiuYlIOlXpPGFwSCvf8pVFcjfCcdOngniU7BR2VfNHrAWCAhrY1UqnQHmayidX6qtW6GAtI7mj1qmeCPKMMEPcY4UVW9uHc0fDSND+Ac9Xak7S5b1lsBdy1i+UczzyVz+yzDnbezR2+Ib+46l4ZOi7K3n6KCSGnsYuuXqRCHR/CKA7c2/KZ+d9ho5X6RIkSJdOvoLXFRTva4YUFgAAAAASUVORK5CYII=`,
    awardName: "Magento 2 ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/fluency/48/000000/adobe-photoshop.png",
    awardName: "Adobe Photoshop ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/adobe-illustrator--v2.png",
    awardName: "Adobe Illustrator ",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/adobe-indesign--v1.png",
    awardName: "Adobe InDesign",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: "https://img.icons8.com/color/48/000000/figma--v1.png",
    awardName: "Figma",
    awardFor: "",
    delayAnimation: "400",
  },
  {
    img: `https://img.icons8.com/plasticine/100/000000/sketch.png`,
    awardName: "Sketch  ",
    awardFor: "",
    delayAnimation: "400",
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Awards = () => {
  return (
    <>
      <div className="row">
        <Slider {...settings}>
          {AwardContnet.map((val, i) => (
            <div
              className="col-lg-4 m-15px-tb mx-3"
              key={i}
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay={val.delayAnimation}
            >
              <div className="feature-box-02 d-flex align-items-center">
                <div className="icon">
                  <img src={val.img} alt="award" />
                </div>
                <div className="media-body">
                  <h6>{val.awardName}</h6>
                  <p>{val.awardFor}</p>
                </div>
              </div>
            </div>
            // End .col
          ))}
        </Slider>
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
