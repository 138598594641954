import React, {useState} from "react";
import { useForm } from "react-hook-form";
import axios from "axios";


const Contact = () => {
  const [sendMailBtnText, setSendMailBtnText] = useState("Skicka");
  const [disableSendMailBtn, setDisableSendMailBtn] = useState(false);
  const [sendMailMessage, setSendMailMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    setSendMailBtnText("loading...");
    setDisableSendMailBtn(true);
  
    var formData = data;
    var name = formData.name;
    var email = formData.email;
    var subject = formData.subject;
    var comment = formData.comment;
    var url = "./omdomen/contact_form.php?name="+name+"&&email="+email+"&&subject="+subject+"&&comment="+comment;

    axios.get(url).then((response)=> {
      if(response.data == "1"){
        setSendMailMessage("FÖRFRÅGAN HAR SKICKATS");
        setSendMailBtnText("Skicka");
        setDisableSendMailBtn(false);
      }else{
        setSendMailMessage("Something went wrong.")
        setSendMailBtnText("Skicka");
        setDisableSendMailBtn(false);
      }
    })
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id={"id"}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Namn"
                {...register("name", { required: true })}
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Namn is required</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="email"
                className="form-control theme-light"
                placeholder="E-postadress"
                {...register(
                  "email",
                  {
                    required: "E-postadress is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message:
                        "Entered value does not match E-postadress format",
                    },
                  },
                  { required: true }
                )}
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Telefonnummer"
                {...register("subject", { required: true })}
              />
              {errors.subject && (
                <span className="invalid-feedback">
                  Telefonnummer is required.
                </span>
              )}
            </div>
          </div>
          {/* End .col-md-6 */}

          <div className="col-md-6">
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control theme-light"
                placeholder="Ämne"
                {...register("subject", { required: true })}
              />
              {errors.subject && (
                <span className="invalid-feedback">Ämne is required.</span>
              )}
            </div>
          </div>
          {/* End .col-md-6 */}

          <div className="col-12">
            <div className="form-group mb-3">
              <textarea
                rows="4"
                className="form-control theme-light"
                placeholder="Meddelande"
                {...register("comment", { required: true })}
              ></textarea>
              {errors.comment && (
                <span className="invalid-feedback">
                  Meddelande is required.
                </span>
              )}
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <button className="px-btn px-btn-white" disabled={disableSendMailBtn}>{sendMailBtnText}</button>
            </div>
          </div>
          {/* End .col-12 */}

          <div className="col-12">
            <div className="btn-bar">
              <p className={"text-success mt-4"}>{sendMailMessage}</p>
            </div>
          </div>
          {/* End .col-12 */}
        </div>
      </form>
    </>
  );
};

export default Contact;
