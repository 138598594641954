import { Accordion } from "react-bootstrap";
import "./Collaps.css";

export default function CollapsFull() {
  return (
    <div className="custom-collaps">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Behovsanalys </Accordion.Header>
          <Accordion.Body>
            Vi sitter tillsammans för att för att identifiera förutsättningar,
            syfte och mål för att vidare göra en projektplanering.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Uppstartsmöte</Accordion.Header>
          <Accordion.Body>
            Vi tillsammans går igenom detaljer kring projektet såsom kravlista,
            tidsplan, lösningsförslag och budget. Ni erhåller dokumentation från
            uppstartsmötet för godkännande.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Designprototyp</Accordion.Header>
          <Accordion.Body>
            Vi arbetar fram en struktur och designlayout. Efter feedback och
            eventuella korrigeringar färdigställer vi design och struktur för
            vidare slutgodkännande.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Innehåll</Accordion.Header>
          <Accordion.Body>
            Allt material och innehåll som behövs säkerställer vi tillgång till.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>Utveckling</Accordion.Header>
          <Accordion.Body>
            Vi påbörjar produktionen där vi lägger in i innehåll, implementerar
            design, utveckling av speciallösningar och integrationer.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>Sluttestning</Accordion.Header>
          <Accordion.Body>
            Vid färdig produktion, testar vi för säkerställa att allting
            fungerar korrekt.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>Leverans och driftsättning</Accordion.Header>
          <Accordion.Body>
            Vi presenterar resultat av projektet för er. Därefter driftsätter vi
            produktionen.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>Förvaltning</Accordion.Header>
          <Accordion.Body>
            Efter driftsättning förvaltar ni produktionen och ni erhåller en
            enklare manual för själva kunna göra ändringar.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
